@use '@angular/material' as mat;
@import 'variables';

@mixin utils {
  .full-height {
    height: 100%;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .flex-intrinsic {
    flex: 0 auto;
  }

  .flex-fill {
    flex: 1;
  }

  .flex-spacer {
    flex: 1 1;
  }

  .m-1 {
    margin: 1rem;
  }

  .m-t-1 {
    margin-top: 1rem;
  }

  .m-t-2 {
    margin-top: 2rem;
  }

  .m-t-3 {
    margin-top: 3rem;
  }

  .m-t-4 {
    margin-top: 4rem;
  }

  .m-v-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .m-h-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .success-color {
    color: mat.get-color-from-palette($success);
  }

  .overflow-auto {
    overflow: auto;
  }

  .unstyled-link {
    &,
    &:visited {
      text-decoration: none;
      color: inherit;
      outline: none;
    }
  }
}
