@mixin form-utils {
  .horizontal-form {
    display: flex;
    align-items: flex-start;

    button[type='submit'] {
      margin: 0.5rem 0 0 1rem;
    }

    .full-width {
      flex: 1;
    }
  }
}
