/* You can add global styles to this file, and also import other style files */
@import 'styles/material-theme';
@import 'styles/document';
@import 'styles/form-util';
@import 'styles/typography';
@import 'styles/layout';
@import 'styles/material-fix';
@import 'styles/reset';
@import 'styles/utils';

@include document;
@include form-utils;
@include typography;
@include layout;
@include material-fix;
@include reset;
@include utils;
