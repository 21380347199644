@mixin document {
  * {
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 14px;
  }
}
