@mixin layout {
  .kdgh-level {
    display: flex !important;
    align-items: flex-start;

    & > * {
      margin-right: 0.5rem !important;
    }

    :last-child {
      margin-right: 0 !important;
    }

    &.is-centered {
      align-items: center !important;
    }
  }
}
